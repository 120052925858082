import _process from "process";
var exports = {};
var process = _process;
var isWindows = process.platform === "win32";
var trailingSlashRe = isWindows ? /[^:]\\$/ : /.\/$/; // https://github.com/nodejs/node/blob/3e7a14381497a3b73dda68d05b5130563cdab420/lib/os.js#L25-L43

exports = function () {
  var path;

  if (isWindows) {
    path = process.env.TEMP || process.env.TMP || (process.env.SystemRoot || process.env.windir) + "\\temp";
  } else {
    path = process.env.TMPDIR || process.env.TMP || process.env.TEMP || "/tmp";
  }

  if (trailingSlashRe.test(path)) {
    path = path.slice(0, -1);
  }

  return path;
};

export default exports;